.features10-layout300 {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.features10-max-width {
  gap: var(--dl-space-space-threeunits);
  align-items: center;
}
.features10-section-title {
  align-items: center;
}
.features10-text12 {
  text-align: center;
}
.features10-content {
  align-self: stretch;
  align-items: center;
}
.features10-feature1 {
  align-items: flex-start;
}
.features10-feature1-title {
  text-align: center;
}
.features10-feature2 {
  align-items: flex-start;
}
.features10-feature3 {
  align-items: flex-start;
}
.features10-actions {
  align-items: flex-start;
}
.features10-button1 {
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-halfunit);
}
.features10-button2 {
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-halfunit);
}
.features10-text13 {
  display: inline-block;
}
.features10-text14 {
  display: inline-block;
}
.features10-text15 {
  display: inline-block;
}
.features10-text16 {
  display: inline-block;
}
.features10-text17 {
  display: inline-block;
}
.features10-text18 {
  display: inline-block;
}
.features10-text19 {
  display: inline-block;
}
.features10-text20 {
  display: inline-block;
}
.features10-text21 {
  display: inline-block;
}
.features10-text22 {
  display: inline-block;
}
.features10-text23 {
  display: inline-block;
}
@media(max-width: 991px) {
  .features10-section-title {
    width: 100%;
  }
}
@media(max-width: 767px) {
  .features10-section-title {
    width: auto;
  }
  .features10-text11 {
    text-align: center;
  }
  .features10-feature2 {
    width: auto;
  }
  .features10-feature3 {
    width: auto;
  }
}
@media(max-width: 479px) {
  .features10-max-width {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .features10-actions {
    width: 100%;
    flex-direction: column;
  }
  .features10-button1 {
    width: 100%;
  }
  .features10-button2 {
    width: 100%;
  }
}
