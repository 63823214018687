.logos1-container1 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.logos1-max-width {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.logos1-text1 {
  text-align: center;
}
.logos1-logo1 {
  object-fit: contain;
}
.logos1-logo2 {
  object-fit: contain;
}
.logos1-logo3 {
  object-fit: contain;
}
.logos1-logo4 {
  object-fit: contain;
}
.logos1-logo5 {
  object-fit: contain;
}
.logos1-logo6 {
  object-fit: contain;
}
.logos1-text2 {
  display: inline-block;
}
@media(max-width: 767px) {
  .logos1-container1 {
    gap: var(--dl-space-space-twounits);
  }
  .logos1-max-width {
    gap: var(--dl-space-space-twounits);
  }
}
