.stats10-max-width {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.stats10-container2 {
  gap: var(--dl-space-space-twounits);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  z-index: 100;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.stats10-title {
  text-align: center;
}
.stats10-content {
  text-align: center;
}
.stats10-container3 {
  width: 100%;
  z-index: 100;
}
.stats10-container4 {
  gap: var(--dl-space-space-unit);
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-theme-neutral-light);
}
.stats10-text11 {
  text-align: center;
}
.stats10-container5 {
  gap: var(--dl-space-space-unit);
  flex: 1;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-theme-neutral-light);
}
.stats10-text14 {
  text-align: center;
}
.stats10-container6 {
  gap: var(--dl-space-space-unit);
  flex: 1;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-theme-neutral-light);
}
.stats10-text17 {
  text-align: center;
}
.stats10-image {
  left: 0px;
  width: 100%;
  bottom: 0px;
  height: 100%;
  position: absolute;
  object-fit: cover;
}
.stats10-text19 {
  display: inline-block;
}
.stats10-text20 {
  display: inline-block;
}
.stats10-text21 {
  display: inline-block;
}
.stats10-text22 {
  display: inline-block;
}
.stats10-text23 {
  display: inline-block;
}
.stats10-text24 {
  display: inline-block;
}
.stats10-text25 {
  display: inline-block;
}
.stats10-text26 {
  display: inline-block;
}
.stats10-text27 {
  display: inline-block;
}
.stats10-text28 {
  display: inline-block;
}
.stats10-text29 {
  display: inline-block;
}
@media(max-width: 767px) {
  .stats10-title {
    text-align: center;
  }
  .stats10-container4 {
    align-self: flex-start;
  }
  .stats10-text10 {
    align-self: center;
  }
  .stats10-container5 {
    align-self: flex-start;
  }
  .stats10-text13 {
    align-self: center;
  }
  .stats10-container6 {
    align-self: flex-start;
  }
  .stats10-text16 {
    align-self: center;
  }
}
@media(max-width: 479px) {
  .stats10-title {
    align-self: center;
    text-align: center;
  }
}
