.timeline1-max-width {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.timeline1-timeline {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.timeline1-progress0 {
  flex: 0 0 auto;
  width: 5%;
  height: 2px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-theme-neutral-dark);
}
.timeline1-step0 {
  flex: 0 0 auto;
  width: auto;
  cursor: pointer;
  margin: -5px;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.timeline1-container11 {
  flex: 0 0 auto;
  width: 12px;
  height: 12px;
  display: flex;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-theme-neutral-dark);
}
.timeline1-container12 {
  flex: 0 0 auto;
  width: 10%;
  height: 2px;
  display: flex;
  opacity: 0.35;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-theme-neutral-dark);
}
.timeline1-container13 {
  flex: 0 0 auto;
  width: 10%;
  height: 2px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-theme-neutral-dark);
}
.timeline1-container14 {
  flex: 0 0 auto;
  width: auto;
  cursor: pointer;
  margin: -5px;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.timeline1-text11 {
  fill: var(--dl-color-theme-neutral-dark);
  color: var(--dl-color-theme-neutral-dark);
  opacity: 0.35;
}
.timeline1-container15 {
  flex: 0 0 auto;
  width: 12px;
  height: 12px;
  display: flex;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-theme-neutral-dark);
}
.timeline1-container16 {
  flex: 0 0 auto;
  width: auto;
  cursor: pointer;
  margin: -5px;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.timeline1-text12 {
  fill: var(--dl-color-theme-neutral-dark);
  color: var(--dl-color-theme-neutral-dark);
  opacity: 0.35;
}
.timeline1-container17 {
  flex: 0 0 auto;
  width: 12px;
  height: 12px;
  display: flex;
  opacity: 0.35;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-theme-neutral-dark);
}
.timeline1-container18 {
  flex: 0 0 auto;
  width: 10%;
  height: 2px;
  display: flex;
  opacity: 0.35;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-theme-neutral-dark);
}
.timeline1-container19 {
  flex: 0 0 auto;
  width: 10%;
  height: 2px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-theme-neutral-dark);
}
.timeline1-container20 {
  flex: 0 0 auto;
  width: auto;
  cursor: pointer;
  margin: -5px;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.timeline1-text13 {
  fill: var(--dl-color-theme-neutral-dark);
  color: var(--dl-color-theme-neutral-dark);
  opacity: 0.35;
}
.timeline1-container21 {
  flex: 0 0 auto;
  width: 12px;
  height: 12px;
  display: flex;
  opacity: 0.35;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-theme-neutral-dark);
}
.timeline1-container22 {
  flex: 0 0 auto;
  width: auto;
  cursor: pointer;
  margin: -5px;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.timeline1-text14 {
  fill: var(--dl-color-theme-neutral-dark);
  color: var(--dl-color-theme-neutral-dark);
  opacity: 0.35;
}
.timeline1-container23 {
  flex: 0 0 auto;
  width: 12px;
  height: 12px;
  display: flex;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-theme-neutral-dark);
}
.timeline1-container24 {
  flex: 0 0 auto;
  width: 10%;
  height: 2px;
  display: flex;
  opacity: 0.35;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-theme-neutral-dark);
}
.timeline1-container25 {
  flex: 0 0 auto;
  width: 10%;
  height: 2px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-theme-neutral-dark);
}
.timeline1-container26 {
  flex: 0 0 auto;
  width: auto;
  cursor: pointer;
  margin: -5px;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.timeline1-text15 {
  fill: var(--dl-color-theme-neutral-dark);
  color: var(--dl-color-theme-neutral-dark);
  opacity: 0.35;
}
.timeline1-container27 {
  flex: 0 0 auto;
  width: 12px;
  height: 12px;
  display: flex;
  opacity: 0.35;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-theme-neutral-dark);
}
.timeline1-container28 {
  flex: 0 0 auto;
  width: auto;
  cursor: pointer;
  margin: -5px;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.timeline1-text16 {
  fill: var(--dl-color-theme-neutral-dark);
  color: var(--dl-color-theme-neutral-dark);
  opacity: 0.35;
}
.timeline1-container29 {
  flex: 0 0 auto;
  width: 12px;
  height: 12px;
  display: flex;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-theme-neutral-dark);
}
.timeline1-container30 {
  flex: 0 0 auto;
  width: 5%;
  height: 2px;
  display: flex;
  opacity: 0.35;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-theme-neutral-dark);
}
.timeline1-container31 {
  flex: 0 0 auto;
  width: 5%;
  height: 2px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-theme-neutral-dark);
}
.timeline1-container32 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.timeline1-content-container1 {
  gap: var(--dl-space-space-unit);
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.timeline1-container33 {
  gap: var(--dl-space-space-twounits);
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.timeline1-image1 {
  flex: 1;
  max-width: auto;
}
.timeline1-container34 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.timeline1-content-container2 {
  gap: var(--dl-space-space-unit);
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.timeline1-text22 {
  fill: var(--dl-color-theme-neutral-dark);
  color: var(--dl-color-theme-neutral-dark);
  opacity: 0.35;
}
.timeline1-container35 {
  gap: var(--dl-space-space-twounits);
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.timeline1-image2 {
  flex: 1;
  max-width: auto;
}
.timeline1-container36 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.timeline1-content-container3 {
  gap: var(--dl-space-space-unit);
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.timeline1-text27 {
  fill: var(--dl-color-theme-neutral-dark);
  color: var(--dl-color-theme-neutral-dark);
  opacity: 0.35;
}
.timeline1-container37 {
  gap: var(--dl-space-space-twounits);
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.timeline1-image3 {
  flex: 1;
  max-width: auto;
}
.timeline1-container38 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.timeline1-content-container4 {
  gap: var(--dl-space-space-unit);
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.timeline1-text32 {
  fill: var(--dl-color-theme-neutral-dark);
  color: var(--dl-color-theme-neutral-dark);
  opacity: 0.35;
}
.timeline1-container39 {
  gap: var(--dl-space-space-twounits);
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.timeline1-image4 {
  flex: 1;
  max-width: auto;
}
.timeline1-text37 {
  display: inline-block;
}
.timeline1-text38 {
  display: inline-block;
}
.timeline1-text39 {
  display: inline-block;
}
.timeline1-text40 {
  display: inline-block;
}
.timeline1-text41 {
  display: inline-block;
}
.timeline1-text42 {
  display: inline-block;
}
.timeline1-text43 {
  display: inline-block;
}
.timeline1-text44 {
  display: inline-block;
}
.timeline1-text45 {
  display: inline-block;
}
.timeline1-text46 {
  display: inline-block;
}
.timeline1-text47 {
  display: inline-block;
}
.timeline1-text48 {
  display: inline-block;
}
.timeline1-text49 {
  display: inline-block;
}
.timeline1-text50 {
  display: inline-block;
}
.timeline1-text51 {
  display: inline-block;
}
.timeline1-text52 {
  display: inline-block;
}
.timeline1-text53 {
  display: inline-block;
}
.timeline1-text54 {
  display: inline-block;
}
.timeline1-text55 {
  display: inline-block;
}
.timeline1-text56 {
  display: inline-block;
}
@media(max-width: 991px) {
  .timeline1-container32 {
    flex-direction: column;
  }
  .timeline1-container34 {
    flex-direction: column;
  }
  .timeline1-container36 {
    flex-direction: column;
  }
  .timeline1-container38 {
    flex-direction: column;
  }
}
@media(max-width: 767px) {
  .timeline1-max-width {
    gap: 0;
  }
  .timeline1-container32 {
    gap: var(--dl-space-space-twounits);
  }
  .timeline1-container34 {
    gap: var(--dl-space-space-twounits);
  }
  .timeline1-container36 {
    gap: var(--dl-space-space-twounits);
  }
  .timeline1-container38 {
    gap: var(--dl-space-space-twounits);
  }
}
@media(max-width: 479px) {
  .timeline1-progress0 {
    display: none;
  }
  .timeline1-container30 {
    display: none;
  }
  .timeline1-container31 {
    display: none;
  }
  .timeline1-container32 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .timeline1-container33 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    align-items: stretch;
    flex-direction: column;
  }
  .timeline1-container34 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .timeline1-container36 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .timeline1-container38 {
    padding-left: 0px;
    padding-right: 0px;
  }
}
