.timeline111-max-width {
  gap: var(--dl-space-space-twounits);
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.timeline111-container11 {
  gap: var(--dl-space-space-twounits);
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.timeline111-text10 {
  text-align: center;
}
.timeline111-text11 {
  text-align: center;
}
.timeline111-container12 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.timeline111-timeline-container {
  flex: 1;
  width: auto;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.timeline111-step1 {
  display: flex;
  position: relative;
  align-items: flex-start;
  justify-content: flex-end;
}
.timeline111-container13 {
  flex: 1;
  left: 0;
  right: 0;
  width: auto;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.timeline111-container14 {
  width: 12px;
  height: 12px;
  margin: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-round);
  background-color: var(--dl-color-theme-neutral-dark);
}
.timeline111-progress01 {
  height: 100%;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-theme-neutral-dark);
  border-style: solid;
  border-width: 2px;
  flex-direction: column;
  background-color: var(--dl-color-theme-neutral-dark);
}
.timeline111-container15 {
  gap: var(--dl-space-space-unit);
  width: 50%;
  display: flex;
  align-items: flex-start;
  padding-top: 0px;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: flex-start;
}
.timeline111-text15 {
  text-align: left;
}
.timeline111-step2 {
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.timeline111-container16 {
  flex: 1;
  left: 0;
  right: 0;
  width: auto;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.timeline111-container17 {
  width: 12px;
  height: 12px;
  margin: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-round);
  background-color: var(--dl-color-theme-neutral-dark);
}
.timeline111-progress02 {
  height: 100%;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-theme-neutral-dark);
  border-style: solid;
  border-width: 2px;
  flex-direction: column;
  background-color: var(--dl-color-theme-neutral-dark);
}
.timeline111-container18 {
  gap: var(--dl-space-space-unit);
  width: 50%;
  display: flex;
  align-items: flex-end;
  padding-top: 0px;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: flex-start;
}
.timeline111-text18 {
  text-align: right;
}
.timeline111-text19 {
  text-align: right;
}
.timeline111-step3 {
  display: flex;
  position: relative;
  align-items: flex-start;
  justify-content: flex-end;
}
.timeline111-container19 {
  flex: 1;
  left: 0;
  right: 0;
  width: auto;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.timeline111-container20 {
  width: 12px;
  height: 12px;
  margin: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-round);
  background-color: var(--dl-color-theme-neutral-dark);
}
.timeline111-progress03 {
  height: 100%;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-theme-neutral-dark);
  border-style: solid;
  border-width: 2px;
  flex-direction: column;
  background-color: var(--dl-color-theme-neutral-dark);
}
.timeline111-container21 {
  gap: var(--dl-space-space-unit);
  width: 50%;
  display: flex;
  align-items: flex-start;
  padding-top: 0px;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: flex-start;
}
.timeline111-text21 {
  text-align: left;
}
.timeline111-step4 {
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.timeline111-container22 {
  flex: 1;
  left: 0;
  right: 0;
  width: auto;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.timeline111-container23 {
  width: 12px;
  height: 12px;
  margin: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-round);
  background-color: var(--dl-color-theme-neutral-dark);
}
.timeline111-progress04 {
  height: 100%;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-theme-neutral-dark);
  border-style: solid;
  border-width: 2px;
  flex-direction: column;
  background-color: var(--dl-color-theme-neutral-dark);
}
.timeline111-container24 {
  gap: var(--dl-space-space-unit);
  width: 50%;
  display: flex;
  align-items: flex-end;
  padding-top: 0px;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: flex-start;
}
.timeline111-text24 {
  text-align: right;
}
.timeline111-text25 {
  text-align: right;
}
.timeline111-text26 {
  display: inline-block;
}
.timeline111-text27 {
  display: inline-block;
}
.timeline111-text28 {
  display: inline-block;
}
.timeline111-text29 {
  display: inline-block;
}
.timeline111-text30 {
  display: inline-block;
}
.timeline111-text31 {
  display: inline-block;
}
.timeline111-text32 {
  display: inline-block;
}
.timeline111-text33 {
  display: inline-block;
}
.timeline111-text34 {
  display: inline-block;
}
.timeline111-text35 {
  display: inline-block;
}
.timeline111-text36 {
  display: inline-block;
}
.timeline111-text37 {
  display: inline-block;
}
.timeline111-text38 {
  display: inline-block;
}
.timeline111-text39 {
  display: inline-block;
}
.timeline111-text40 {
  display: inline-block;
}
.timeline111-text41 {
  display: inline-block;
}
@media(max-width: 767px) {
  .timeline111-text11 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .timeline111-container13 {
    right: auto;
  }
  .timeline111-container15 {
    width: 100%;
    padding-left: var(--dl-space-space-threeunits);
  }
  .timeline111-text15 {
    text-align: left;
  }
  .timeline111-container16 {
    right: auto;
  }
  .timeline111-container18 {
    width: 100%;
    align-items: flex-start;
    padding-left: var(--dl-space-space-threeunits);
  }
  .timeline111-text18 {
    text-align: left;
  }
  .timeline111-text19 {
    text-align: left;
  }
  .timeline111-container19 {
    right: auto;
  }
  .timeline111-container21 {
    width: 100%;
    padding-left: var(--dl-space-space-threeunits);
  }
  .timeline111-text21 {
    text-align: left;
  }
  .timeline111-container22 {
    right: auto;
  }
  .timeline111-container24 {
    width: 100%;
    align-items: flex-start;
    padding-left: var(--dl-space-space-threeunits);
  }
  .timeline111-text24 {
    text-align: left;
  }
  .timeline111-text25 {
    text-align: left;
  }
}
@media(max-width: 479px) {
  .timeline111-container12 {
    width: 100%;
    align-items: stretch;
    flex-direction: column;
  }
}
