.testimonial10-max-width {
  display: flex;
}
.testimonial10-slider {
  width: 100%;
  height: auto;
  display: inline-block;
  position: relative;
}
.testimonial10-slider-slide1 {
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.testimonial10-content1 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.testimonial10-logo1 {
  height: 2rem;
}
.testimonial10-text10 {
  text-align: center;
}
.testimonial10-avatar1 {
  gap: var(--dl-space-space-unit);
  width: 300px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.testimonial10-avatar-image1 {
  width: 56px;
  height: 56px;
  object-fit: cover;
}
.testimonial10-avatar-content1 {
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.testimonial10-text11 {
  font-style: normal;
  font-weight: 600;
}
.testimonial10-slider-slide2 {
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.testimonial10-content2 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.testimonial10-logo2 {
  height: 2rem;
}
.testimonial10-text13 {
  text-align: center;
}
.testimonial10-avatar2 {
  gap: var(--dl-space-space-unit);
  width: 300px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.testimonial10-avatar-image2 {
  width: 56px;
  height: 56px;
  object-fit: cover;
}
.testimonial10-avatar-content2 {
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.testimonial10-text14 {
  font-style: normal;
  font-weight: 600;
}
.testimonial10-slider-slide3 {
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.testimonial10-content3 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.testimonial10-logo3 {
  height: 2rem;
}
.testimonial10-text16 {
  text-align: center;
}
.testimonial10-avatar3 {
  gap: var(--dl-space-space-unit);
  width: 300px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.testimonial10-avatar-image3 {
  width: 56px;
  height: 56px;
  object-fit: cover;
}
.testimonial10-avatar-content3 {
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.testimonial10-text17 {
  font-style: normal;
  font-weight: 600;
}
.testimonial10-slider-pagination {
  display: block;
}
.testimonial10-text19 {
  display: inline-block;
}
.testimonial10-text20 {
  display: inline-block;
}
.testimonial10-text21 {
  display: inline-block;
}
.testimonial10-text22 {
  display: inline-block;
}
.testimonial10-text23 {
  display: inline-block;
}
.testimonial10-text24 {
  display: inline-block;
}
.testimonial10-text25 {
  display: inline-block;
}
.testimonial10-text26 {
  display: inline-block;
}
.testimonial10-text27 {
  display: inline-block;
}
@media(max-width: 991px) {
  .testimonial10-content1 {
    flex: 1;
    width: 100%;
    max-width: 800px;
  }
  .testimonial10-content2 {
    flex: 1;
    width: 100%;
    max-width: 800px;
  }
  .testimonial10-content3 {
    flex: 1;
    width: 100%;
    max-width: 800px;
  }
}
@media(max-width: 767px) {
  .testimonial10-slider-slide1 {
    padding: var(--dl-space-space-threeunits);
  }
  .testimonial10-slider-slide2 {
    padding: var(--dl-space-space-threeunits);
  }
  .testimonial10-slider-slide3 {
    padding: var(--dl-space-space-threeunits);
  }
}
@media(max-width: 479px) {
  .testimonial10-slider-slide2 {
    padding: var(--dl-space-space-oneandhalfunits);
  }
  .testimonial10-slider-slide3 {
    padding: var(--dl-space-space-oneandhalfunits);
  }
}
