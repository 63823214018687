.blog-post-header12-blog-post-header3 {
  display: flex;
  position: relative;
  align-items: center;
}
.blog-post-header12-max-width {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.blog-post-header12-category {
  border: 2px dashed rgba(120, 120, 120, 0.4);
}
.blog-post-header12-content1 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
}
.blog-post-header12-container {
  flex: 1;
  width: 100%;
  height: 560px;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
}
.blog-post-header12-video {
  width: 100%;
  height: 100%;
  min-width: 300px;
}
.blog-post-header12-section-title {
  align-items: flex-start;
}
.blog-post-header12-title {
  text-align: center;
}
.blog-post-header12-content2 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.blog-post-header12-author {
  gap: var(--dl-space-space-halfunit);
  display: flex;
}
.blog-post-header12-time {
  gap: 8px;
  display: flex;
  align-items: center;
}
.blog-post-header12-share-buttons {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
}
.blog-post-header12-text4 {
  display: inline-block;
}
.blog-post-header12-text5 {
  display: inline-block;
}
.blog-post-header12-text6 {
  display: inline-block;
}
.blog-post-header12-text7 {
  display: inline-block;
}
@media(max-width: 991px) {
  .blog-post-header12-content1 {
    gap: var(--dl-space-space-twounits);
  }
}
@media(max-width: 767px) {
  .blog-post-header12-content1 {
    gap: var(--dl-space-space-unit);
    flex-direction: column;
  }
  .blog-post-header12-container {
    width: 100%;
  }
}
@media(max-width: 479px) {
  .blog-post-header12-section-title {
    align-items: center;
  }
  .blog-post-header12-content2 {
    align-items: center;
  }
}
