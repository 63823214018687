.banner3-container1 {
  gap: var(--dl-space-space-unit);
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.banner3-image {
  left: 0px;
  width: 100%;
  bottom: 0px;
  height: 100%;
  position: absolute;
}
.banner3-max-width {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 100;
  align-items: center;
  flex-direction: column;
}
.banner3-container2 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  z-index: 100;
  align-items: center;
  flex-direction: column;
}
.banner3-title {
  text-align: center;
}
.banner3-text1 {
  text-align: center;
}
.banner3-text3 {
  display: inline-block;
}
.banner3-text4 {
  display: inline-block;
}
.banner3-text5 {
  display: inline-block;
}
