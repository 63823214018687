.faq6faq7 {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.faq6-section-title {
  align-items: center;
}
.faq6-text10 {
  text-align: center;
}
.faq6-text11 {
  text-align: center;
}
.faq6-list-item1 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.faq6-faq1-question {
  align-self: center;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.faq6-faq1-answer {
  text-align: center;
}
.faq6-list-item2 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.faq6-faq2-question {
  align-self: center;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.faq6-faq2-answer {
  text-align: center;
}
.faq6-list-item3 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.faq6-faq3-question {
  align-self: center;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.faq6-faq3-answer {
  text-align: center;
}
.faq6-list-item4 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.faq6-faq4-question {
  align-self: center;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.faq6-faq4-answer {
  text-align: center;
}
.faq6-list-item51 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.faq6-faq5-question1 {
  align-self: center;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.faq6-faq5-answer1 {
  text-align: center;
}
.faq6-list-item52 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.faq6-faq5-question2 {
  align-self: center;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.faq6-faq5-answer2 {
  text-align: center;
}
.faq6-content2 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.faq6-text12 {
  text-align: center;
}
.faq6-text13 {
  text-align: center;
}
.faq6-text14 {
  display: inline-block;
}
.faq6-text15 {
  display: inline-block;
}
.faq6-text16 {
  display: inline-block;
}
.faq6-text17 {
  display: inline-block;
}
.faq6-text18 {
  display: inline-block;
}
.faq6-text19 {
  display: inline-block;
}
.faq6-text20 {
  display: inline-block;
}
.faq6-text21 {
  display: inline-block;
}
.faq6-text24 {
  display: inline-block;
}
.faq6-text25 {
  display: inline-block;
}
.faq6-text26 {
  display: inline-block;
}
.faq6-text27 {
  display: inline-block;
}
.faq6-text28 {
  display: inline-block;
}
.faq6-text29 {
  display: inline-block;
}
.faq6-text30 {
  display: inline-block;
}
.faq6-text31 {
  display: inline-block;
}
.faq6-text32 {
  display: inline-block;
}
@media(max-width: 767px) {
  .faq6-max-width {
    width: 100%;
  }
}
