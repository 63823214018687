.hero2-header5 {
  width: 100%;
  height: 100vh;
  display: flex;
  position: relative;
  align-items: flex-start;
  justify-content: flex-end;
}
.hero2-image {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
}
.hero2-container1 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}
.hero2-max-width {
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: flex-start;
}
.hero2-column {
  gap: 24px;
  width: auto;
  display: flex;
  z-index: 1;
  max-width: 560px;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.hero2-content {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  animation-name: fadeInLeftBig;
  flex-direction: column;
  animation-delay: 0s;
  animation-duration: 500ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.hero2-actions {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
}
.hero2-container2 {
  display: flex;
  align-items: flex-start;
}
.hero2-button1 {
  display: flex;
  box-sizing: content-box;
  align-items: center;
  border-color: var(--dl-color-theme-primary1);
  border-style: solid;
  border-width: 1px;
  justify-content: center;
  background-color: var(--dl-color-theme-primary1);
}
.hero2-container3 {
  display: flex;
  align-items: flex-start;
}
.hero2-text5 {
  display: inline-block;
}
.hero2-text6 {
  display: inline-block;
}
.hero2-text7 {
  display: inline-block;
}
.hero2-text8 {
  display: inline-block;
}
@media(max-width: 767px) {
  .hero2-header5 {
    justify-content: center;
  }
  .hero2-max-width {
    justify-content: center;
  }
  .hero2-column {
    width: 100%;
  }
  .hero2-text1 {
    text-align: center;
  }
  .hero2-text2 {
    text-align: center;
  }
  .hero2-actions {
    width: 100%;
    align-self: flex-start;
    justify-content: center;
  }
}
@media(max-width: 479px) {
  .hero2-actions {
    flex-direction: column;
  }
  .hero2-container2 {
    width: 100%;
  }
  .hero2-button1 {
    width: 100%;
  }
  .hero2-container3 {
    width: 100%;
  }
  .hero2-button2 {
    width: 100%;
  }
}
